
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component({
        props: {
            title: {
                type: String,
                default: '',
            },
        },
    })
    export default class TheHeaderStatic extends Vue {
    }
